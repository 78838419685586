module.exports = (function () {

  $(".modal-show").fancybox({
    // 'titlePosition' : 'inside',
    'transitionIn' : 'none',
    'transitionOut' : 'none'
});
  
  // let wrap = $('.page-wrapper'),
  //   btn = $('.modal-show'),
  //   close = $('.modal__close'),
  //   modal = $('.cover, .modal, .modal__content');

  // $('.page-wrapper').removeClass('no-scroll');
  
  // // if(modal.length) {
  // //   modal.css("display", "none");
  // // }  

  // btn.on('click', function() {
  //   modal.fadeIn();    
  //   $('.page-wrapper').addClass('no-scroll');
  // });

  // close.on('click', function() {
  //   modal.fadeOut();
  //   modal.css("display", "none");
  //   $('.page-wrapper').removeClass('no-scroll');
  // });

  // modal.click(function() {
  //   wrap.on('click', function(event) {
  //     let select = $('.modal__content');
  //     if ($(event.target).closest(select).length)
  //       return;
  //     modal.fadeOut();
  //     modal.css("display", "none");
  //     wrap.unbind('click');
  //     $('.page-wrapper').removeClass('no-scroll');
  //   });
  // });

  
  $(".form").submit(function (event) {
  event.preventDefault();
    
    let form = $('#' + $(this).attr('id'))[0];
    let inpNameError = $(this).find('.form__error-name');
    let inpTelError = $(this).find('.form__error-phone');
  
    let formDescription = $(this).find('.form__description');
  
    let fd = new FormData(form);
    $.ajax({
      url: "/mail.php",
      type: "POST",
      data: fd,
      processData: false,
      contentType: false,
      success: function success(res) {
        let respond = $.parseJSON(res);

        if (respond.name) {
          inpNameError.text(respond.name);
        } else {
          inpNameError.text('');
        }

        if (respond.tel) {
          inpTelError.text(respond.tel);
        } else {
          inpTelError.text('');
        }

        if (respond.attantion) {
          formDescription.text(respond.attantion).css('color', '#e84a66').fadeIn();
        } else {
          formDescription.text('');
        }

        if (respond.success) {
          formDescription.text(respond.success).fadeIn();
          setTimeout(() => {
            formDescription.fadeOut("slow");
          }, 4000);
          setTimeout(() => {
            formDescription.text('');
          }, 5000);
        }
      },
    });
  });    
})();