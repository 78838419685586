module.exports = (function(){

  if ($('.counters').length) {
    let show = true;  

    $(window).on('scroll load resize', function() {
      
      if(!show) return false;
      let w_top = $(window).scrollTop();
      let e_top = $('.counters').offset().top;
      let w_height = $(window).height();
      let d_height = $(document).height();
      let e_height = $('.counters').outerHeight();

      if (w_top + 100 >= e_top || w_height + w_top === d_height || e_height + e_top < w_height) {
        $('.number-counter').spincrement({
          decimalPlaces: 0,
          thousandSeparator: "",
          duration: 3000
        });
        show = false;
      }
    })
  }

})();