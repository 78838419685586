module.exports = (function(){

  $('#sliderCrt').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 4,
    arrows: false,
    speed: 300,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1
      }
    }
    ]
  });  

  $(".next_btn-crtf").click(function(){
    $('#sliderCrt').slick("slickNext");
  });
   
  $(".prev_btn-crtf").click(function(){
    $('#sliderCrt').slick("slickPrev");
  });


  $('#sliderReviews').slick({
    dots: false,
    autoplay: true,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: 1,
    arrows: false,
    speed: 300
  });

  $(".next_btn-reviews").click(function(){
    $('#sliderReviews').slick("slickNext");
  });
   
  $(".prev_btn-reviews").click(function(){
    $('#sliderReviews').slick("slickPrev");
  });

  function accrd() {
    $('.accrd__trigger').on('click', function() {
      
      if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $(this).siblings('.accrd__content').slideUp('swing');
      } else {
        $('.accrd__trigger').removeClass('open');
        $('.accrd__content').slideUp('swing');
        $(this).addClass('open');
        $(this).siblings('.accrd__content').slideDown('swing');
      }

    })
  }
  accrd();

  $(".section-certificates__item").find('a').fancybox({
    // 'titlePosition' : 'inside',
    'transitionIn' : 'none',
    'transitionOut' : 'none'
  });

  // $(function(){
  //   $('.minimized').click(function(event) {
  //     var i_path = $(this).attr('src');
  //     $('body').append('<div id="overlay"></div><div id="magnify"><img src="'+i_path+'"><div id="close-popup"><i></i></div></div>');
      
  //     $('#overlay, #magnify').fadeIn('fast');
  //   });
    
  //   $('body').on('click', '#close-popup, #overlay', function(event) {
  //     event.preventDefault();
   
  //     $('#overlay, #magnify').fadeOut('fast', function() {
  //       $('#close-popup, #magnify, #overlay').remove();
  //     });
  //   });
  // });  
 

})();