module.exports = (function(){
	$('.langs__visible').on("click", function () {
		if (!$('.langs__visible').hasClass('active')) {
			$('.langs__visible').addClass('active');
			$('.langs__list').addClass('active');
			$('.langs').addClass('langs-active');
		} else {
			$('.langs__visible').removeClass('active');
			$('.langs__list').removeClass('active');
			$('.langs').removeClass('langs-active');
		}
	});  

	$('main').on("click", function () {
		if ($('.langs__visible').hasClass('active')) {
			$('.langs__visible').removeClass('active');
			$('.langs__list').removeClass('active');
			$('.langs').removeClass('langs-active');
		} 
	});  
	$('header').on("click", function (e) {
		let target = e.target;
		
		if ($(target).hasClass('langs__link') == false) {
			$('.langs__visible').removeClass('active');
			$('.langs__list').removeClass('active');
			$('.langs').removeClass('langs-active');
		} 
	}); 
	
	
	let $header = $('.header'); 
	let $section = $('.section-home'); 

	if ($('main').hasClass('home-page')) {
		$section = $('.section-home'); 
		
	  $(window).on('scroll mousewheel', function(event) {   
		
		// if (event.originalEvent.wheelDelta >= 0) {
			
			if ($(window).scrollTop() > 0) {  //($section.height())
				  
	      $('header').addClass('fixed');
	    } else {
	      $('header').removeClass('fixed');
			} 	    
	 
		// }
	}); 
	}
		
	if ($('main').hasClass('other-page')) {
		$section = $('.page-title-wrap');
		 
	  $(window).on('scroll mousewheel', function(event) { 
			
			// if (event.originalEvent.wheelDelta >= 0) {
				
				if ($(window).scrollTop() > 0) {      //($('header').height() + $section.height())

					$('header').addClass('fixed');
				} else {
					$('header').removeClass('fixed');
				}  
			// }   
	  });  
	}

	if ($('.home-page').length) {
		$('header').scroolly([
			{
			to: 'con-top + 100el',
			direction: 1,
			css: {
					'-transition': 'none',
					position: 'absolute',
					top: ''
			}
	},
	{
			direction: 1,
			from: 'con-top + 100el',
			css: {                       
					'-transition': 'none',
					position: 'fixed',
					top: '-100px'
			}
	},
	{
			to: 'con-top',
			direction: -1,
			css: {
					'-transition': 'none',
					position: 'absolute',
					top: ''
			}
	},
	{
			direction: -1,
			from: 'con-top',
			css: {
					'-transition': 'all .2s',
					position: 'fixed',
					top: '0'
			}
	}
	], $('.home-page'));
	}
	
	if ($('.other-page').length) {
		$('header').scroolly([
			{
			to: 'con-top + 100el',
			direction: 1,
			css: {
					'-transition': 'none',
					position: 'absolute',
					top: ''
			}
		},
		{
			direction: 1,
			from: 'con-top + 100el',
			css: {                       
					'-transition': 'none',
					position: 'fixed',
					top: '-100px'
			}
		},
		{
			to: 'con-top',
			direction: -1,
			css: {
					'-transition': 'none',
					position: 'absolute',
					top: ''
			}
		},
		{
			direction: -1,
			from: 'con-top',
			css: {
					'-transition': 'all .2s',
					position: 'fixed',
					top: '0'
			}
		}
		], $('.other-page'));
	}

	

})();