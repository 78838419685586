module.exports = (function(){
  // $(function(){
    // $('.minimized').click(function(event) {
    //   var i_path = $(this).attr('src');
    //   $('body').append('<div id="overlay"></div><div id="magnify"><img src="'+i_path+'"><div id="close-popup"><i></i></div></div>');
      
    //   $('#overlay, #magnify').fadeIn('fast');
    // });
    
  //   $('body').on('click', '#close-popup, #overlay', function(event) {
  //     event.preventDefault();
   
  //     $('#overlay, #magnify').fadeOut('fast', function() {
  //       $('#close-popup, #magnify, #overlay').remove();
  //     });
  //   });
  // });  
  
  // if ($('.fotorama')) {
  //   $('.fotorama').fotorama({
  //     width: '100%',
  //     maxwidth: '100%',
  //     maxhight: '100%',
      
  //     thumbwidth: '25%',
  //     navwidth: '100%',
  //     thumbborderwidth: '1',
      
  //     //ratio: 16/14,
  //     //allowfullscreen: true,
  //     nav: 'thumbs'
  //    });
  // }  

  $(".section-single__doc").find('a').fancybox({
    // 'titlePosition' : 'inside',
    'transitionIn' : 'none',
    'transitionOut' : 'none'
  });

  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav'
  });
  $('.slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    //centerMode: true,
    focusOnSelect: true
  });

})();