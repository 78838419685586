module.exports = (function () {
 	const burger = $('.burger');
	const nav = $('.navigation');
	const page = $('#fullpage');

	burger.click(function () {
		$(this).toggleClass('active');
		nav.toggleClass('open');
		page.toggleClass('no-scroll');
	});
	$(".navigation a").on("click",function() {
		burger.removeClass('active');
		nav.removeClass('open');
		page.removeClass('no-scroll');
	});   

})();