module.exports = (function(){
  
  if ($('#map')) {
    ymaps.ready(function () {
      const myMap = new ymaps.Map("map", {
        center: [53.643194, 23.826672], 
        zoom: 15,
        controls: []
      });
      myMap.behaviors.disable(['rightMouseButtonMagnifier', 'ruler']);  
      
      const myPlacemark = new ymaps.Placemark([53.643194, 23.826672], {}, {  
        preset: 'islands#blueStretchyIcon',
        draggable: false
      });
      myMap.geoObjects.add(myPlacemark);    
    }); 
  }    
  
})();